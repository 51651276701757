<template>
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col d-flex justify-content-end">
          <input type="text" v-model="searchQuery" @input="handleSearch" placeholder="Search..." class="form-control" style="max-width: 200px; margin-right: 1%;">
          <div v-if="showGenerateReportButton">
            <button class="btn btn-primary report" @click="exportToExcel">
              Export to PIM
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Select</th>
                <th v-for="column in columns" :key="column.field">{{ column.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows" :key="row.id">
                <td>
                  <input type="checkbox" :checked="isRowSelected(row)" @click="toggleRowSelection(row)" />
                </td>
                <td v-for="column in columns" :key="column.field">{{ row[column.field] }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="isLoading" class="text-center">
            <span>Loading...</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-between">
          <div>
            <label for="pageSize">Rows per page:</label>
            <select v-model="pageSize" id="pageSize" @change="changePageSize" class="form-control d-inline-block" style="width: auto;">
              <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
            </select>
          </div>
          <div>
            <button @click="prevPage" :disabled="page === 1" class="btn btn-secondary mr-2">Previous</button>
            <span>Page {{ page }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="page === totalPages" class="btn btn-secondary ml-2">Next</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useToast } from "vue-toast-notification";
  import { token } from "./token.js";
  import axios from "axios";
  import { useDeliveryNotesStore } from '@/stores/deliveryNote.js';
  import { useGoodsStore } from "@/stores/goods";
  import { useDeliveryNotePrintedStore } from '@/stores/deliveryNotePrinted.js';

  const $toast = useToast();

  export const apiInstance = axios.create({
    responseType: "json",
    headers: {
      //'Content-Type': 'application/json',
      // you dont need to pre-define all headers
    },
  });
  apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  export default {
    name: 'Delivery Report',
    data() {
      return {
        ids: [],
        isSearch: false,
        isLoading: false,
        remoteParams: {},
        _selectionIds: [],
        _projectCodes: [],
        rows: [],
        codes: [],
        users: [],
        searchQuery: "",
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        totalCount: 0,
        sortBy: "Id",
        sortDirection: "asc",
        pageTitle: 'InHouse Bags Report',
        columns: [
          { label: "Id", field: "id", type: "number" },
          { label: "Bag Number", field: "bagNumber" },
          { label: "Part Number", field: "partNumber" },
          { label: "Inspection Type", field: "inspectionType" },
          { label: "Quality", field: "quality" },
          { label: "Quantity Issued", field: "quantityIssued", type: "number" },
          { label: "Quantity Passed", field: "quantityPassed", type: "number" },
          { label: "Quantity Failed", field: "quantityFailed", type: "number" },
          { label: "Notes", field: "notes" },
        ],
        selectedRows: [],
        showGenerateReportButton: false,
      };
    },
    watch: {
      page() {
        this.fetchRemoteData();
      },
      pageSize() {
        this.page = 1; // Reset to the first page whenever page size changes
        this.fetchRemoteData();
      }
    },
    computed: {
      showGenerateReportButton() {
        return this.selectedRows.length > 0;
      },
      canPrint() {
        return this._selectionIds.value.length > 0 && this._projectCodes.value.length <= 1;
      },
      filteredRows() {
        if (!this.searchQuery) {
          return this.rows;
        }
        const searchQueryLower = this.searchQuery.toLowerCase();
        return this.rows.filter(row =>
          this.columns.some(column =>
            String(row[column.field]).toLowerCase().includes(searchQueryLower)
          )
        );
      },
      paginatedRows() {
        const start = (this.page - 1) * this.pageSize;
        const end = start + this.pageSize;
        return this.filteredRows.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.totalCount / this.pageSize); // Calculate pages based on totalCount and pageSize
      },
    },
    created() {
      this.fetchRemoteData();
    },
    methods: {
      async exportToExcel() {
        import("xlsx").then(async (XLSX) => {
          // Prepare the data structure
          const exportData = this.selectedRows.map(row => ({
            OrganizationExternalId: "",
            OrganizationName: "TrelloBorg",
            ProjectExternalId: "",
            ProjectName: row.partNumber,
            TaskExternalId: "",
            TaskName: row.bagNumber,
            MetaDataOutputHourlyRateInspectra: "", // Placeholder to fill after fetching
            MetaDataTotalQuantity: row.quantityIssued,
          }));

          // Fetch hourly rates for selected rows
          const ids = this.selectedRows.map(row => row.id);
          console.log("ids", ids);
          const goodsStore = new useGoodsStore();
          
          // Ensure the response is parsed as JSON
          const response = await goodsStore.PIMReport(ids);
          const data = await response.json();

          // Check if data is an array
          if (!Array.isArray(data)) {
            console.error("Error fetching hourly rates: Data is not an array", data);
            return;
          }

          // Map response data to exportData, assuming data matches order of selectedRows
          data.forEach((hourlyRate, index) => {
            if (exportData[index]) {
              exportData[index].MetaDataOutputHourlyRateInspectra = hourlyRate;
            }
          });

          // Create the Excel sheet
          const worksheet = XLSX.utils.json_to_sheet(exportData);

          // Apply a light blue background color to header cells
          const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (worksheet[cellAddress]) {
              worksheet[cellAddress].s = {
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: "ADD8E6" } // Light blue background color
                }
              };
            }
          }

          // Create workbook and append sheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "ExportedData");

          // Trigger download
          XLSX.writeFile(workbook, "ExportedData.xlsx");
        }).catch(error => {
          console.error("Error fetching hourly rates:", error);
        });
      },
      async fetchRemoteData() {
        const remoteParams = {
          page: this.page,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          sortDirection: this.sortDirection,
          searchQuery: this.searchQuery,
        };
        this.isLoading = true;
        
        // Assuming `goodsStore.searchInHouseBags()` supports pagination with page and pageSize
        const goodsStore = new useGoodsStore();
        const response = await goodsStore.searchInHouseBags(remoteParams.page, remoteParams.pageSize, remoteParams.sortBy, remoteParams.sortDirection, remoteParams.searchQuery);

        const data = response.items; // Adjust based on response structure
        this.rows = data;            // Set the current page's data to `rows`
        this.isLoading = false;
        this.totalCount = response.totalCount || 0; // Update total count for pagination
      },
      handleSearch() {
        this.page = 1; // Reset to first page on search
      },
      async prevPage() {
        if (this.page > 1) {
          this.page--;
          await this.fetchRemoteData(); // Fetch new data for previous page
        }
      },
      async nextPage() {
        if (this.page < this.totalPages) {
          this.page++;
          await this.fetchRemoteData(); // Fetch new data for next page
        }
      },
      changePageSize() {
        this.page = 1; // Reset to first page
        this.fetchRemoteData(); // Fetch new data with updated page size
      },
      handleRowSelect(data) {
        console.log("selectedRows", data.selectedRows);
        this.selectedRows = data.selectedRows;
      },
      async deliverNo() {
        console.log("selectedRows", this.selectedRows);
        const ids = this.selectedRows.map(row => row.id);
        const bagNumbers = this.selectedRows.map(row => row.bagNumber);
        const deliveryNoteStore = useDeliveryNotePrintedStore();
        const response = await deliveryNoteStore.createDeliveryNotePrinted(ids, bagNumbers);
        if (response.ok) {
          this.$router.push({ name: 'toDeliver', params: { ids: ids.join(',') } });
        }
      },
      refreshData() {
        if (this.$router && this.$router.params && this.$router.params.id) {
          this.id = this.$router.params.id;
          fetch(`${this.apiPrefix}/DeliveryNote/Valid`).then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error("Error fetching Data");
            }
          }).then((data) => {
            this.rows = data;
          }).catch((err) => {
            $toast.error("Error fetching data: " + err);
          });
        } else {
          $toast.error("Invalid URL: 'id' parameter is missing.");
        }
      },
      toggleRowSelection(row) {
        const index = this.selectedRows.findIndex((selectedRow) => selectedRow.id === row.id);
        if (index !== -1) {
          this.selectedRows.splice(index, 1);
        } else {
          this.selectedRows.push(row);
        }
      },
      isRowSelected(row) {
        return this.selectedRows.some((selectedRow) => selectedRow.id === row.id);
      },
    },
  };
  </script>
  
  <style scoped>
  @import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
  
  .report {
    background: rgb(8, 207, 8);
    color: white;
    text-align: center;
    margin-right: 10%;
  }
  </style>
  